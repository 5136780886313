import React from "react";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import img2 from "../Assets/stabilityai.png";
import img3 from "../Assets/geminiai.png";
import img4 from "../Assets/huggingface.png";
import img6 from "../Assets/metaai.png";
import img7 from "../Assets/microsoftfactory.png";
import img8 from "../Assets/mistralai.png";
import img9 from "../Assets/nvidia.png";
import img10 from "../Assets/openai.png";
import FlowDiagram from "./FlowDiagram";
import EfficientAILogo from "../Assets/flowchart-logo.png";
import HeroStatistics from "../Components/HeroStatistics";

const LogoSlider = () => {
  const logos = [
    { src: img2, alt: "Stability AI Logo" },
    { src: img3, alt: "Gemini AI Logo" },
    { src: img4, alt: "Hugging Face Logo" },
    { src: img6, alt: "Meta Logo" },
    { src: img7, alt: "Microsoft AI Logo" },
    { src: img8, alt: "Mistral AI Logo" },
    { src: img9, alt: "NVIDIA Logo" },
    { src: img10, alt: "OpenAI Logo" },
  ];

  return (
    <div className=" logo-parent bg-gray-900 flex flex-col justify-center w-full ">
      <div className=" bg-opacity-60 backdrop-blur-md border flex flex-col gap-10 items-center justify-center border-gray-600 p-6 shadow-lg w-full pb-24">
        <div className="text-center">
          <p className="text-center tracking-widest font-normal text-white my-6 uppercase ">
            Why Efficient<span className="text-yellow-600">AI</span>
          </p>
          <h1 className="text-center mx-auto font-semibold text-3xl lg:text-4xl text-white md:text-3xl my-4">
            Integrate with Prominent Cloud Providers
          </h1>
        </div>
        <FlowDiagram width="80%" />
      </div>
      <HeroStatistics />

      <div className="pt-16 border border-gray-600">
        <h2 className="text-center mx-auto font-semibold text-2xl text-white md:text-3xl ">
          Leverage Industry-Leading Foundational Models All in One Place for
          Your AI Journey
        </h2>

        <div className="relative w-full h-[400px] flex items-center justify-center z-10">
          <motion.div
            className="absolute top-0 left-0 flex items-center justify-center w-full h-full z-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <InView
              as="div"
              triggerOnce={false}
              triggerEnter={() => console.log("Entered the viewport")}
            >
              {({ inView, ref }) => (
                <motion.div
                  ref={ref}
                  className="relative flex items-center justify-center"
                  initial={{ y: -50, opacity: 0.9 }}
                  animate={{
                    y: inView ? 0 : -60,
                    opacity: inView ? 1 : 0.9,
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 200,
                    damping: 20,
                    delay: 0.3,
                  }}
                >
                  <div className="bg-gradient-to-r from-gray-800 to-gray-300 rounded-[30%] p-2 shadow-[0_4px_20px_rgba(255,255,255,0.8)]">
                    <div className="bg-gradient-to-r from-yellow-800 to-yellow-700 rounded-[30%] p-2 shadow-[0_4px_20px_rgba(255,255,255,0.8)]">
                      <img
                        src={EfficientAILogo}
                        alt="Efficient AI Logo"
                        className="w-24 md:w-40 h-auto lg:w-56"
                      />
                    </div>
                  </div>
                </motion.div>
              )}
            </InView>
          </motion.div>

          <div className="relative w-full">
            <Marquee
              direction="left"
              speed={50}
              delay={1}
              className="z-0"
              style={{
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div className="flex justify-between space-x-32 ml-20">
                {logos.map((logo, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center px-2 lg:px-4 bg-gray-800 rounded-lg lg:rounded-2xl shadow-lg ml-20"
                  >
                    <img
                      src={logo.src}
                      alt={logo.alt}
                      className="lg:h-16 lg:w-28 w-20 h-10 object-contain"
                    />
                  </div>
                ))}
                {logos.map((logo, index) => (
                  <div
                    key={index + logos.length}
                    className="flex items-center justify-center px-2 lg:px-4 bg-gray-700 rounded-lg lg:rounded-lg shadow-lg"
                  >
                    <img
                      src={logo.src}
                      alt={logo.alt}
                      className="lg:h-16 lg:w-28 w-24 h-12 object-contain"
                    />
                  </div>
                ))}
              </div>
            </Marquee>

            <div className="absolute top-0 left-0 w-1/4 h-full bg-gradient-to-r from-gray-900 to-transparent z-10"></div>
            <div className="absolute top-0 right-0 w-1/4 h-full bg-gradient-to-l from-gray-900 to-transparent z-10"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoSlider;
