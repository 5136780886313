import React, { useState, useRef } from "react";
import Slider from "react-slick";

const Carousel = ({ reviews }) => {
  const [isHovered, setIsHovered] = useState(false);
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    cssEase: "ease-in-out",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  };

  const hoverEffectStyle = {
    transform: "scale(1.05)",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  };

  return (
    <div className="py-20 bg-gray-900 border border-gray-700 relative">
      <div className="text-3xl md:text-4xl font-bold text-white text-center mb-20">
        Trusted by Enterprise Leaders
      </div>

      <div className="relative w-full overflow-hidden">
        <div className="absolute top-0 left-0 w-1/6 lg:w-1/3 h-full bg-gradient-to-r from-gray-900 to-transparent z-10"></div>
        <div className="absolute top-0 right-0 w-1/6 lg:w-1/3 h-full bg-gradient-to-l from-gray-900 to-transparent z-10"></div>

        <Slider {...settings} ref={sliderRef}>
          {reviews.map((review, index) => (
            <div
              key={index}
              className="carousel-item px-4 "
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={isHovered ? hoverEffectStyle : {}}
            >
              <div className="bg-gray-900 bg-opacity-60 backdrop-blur-lg border-2 border-gray-100 p-6 rounded-xl shadow-xl w-full h-[320px] flex flex-col justify-between cursor-pointer hover:bg-gray-800 hover:text-white">
                <div className="avatar-name-container flex items-center space-x-4 mb-6">
                  <img
                    src={review.avatar}
                    alt={review.name}
                    className="w-16 h-16 rounded-full "
                  />
                  <div>
                    <h3 className="text-lg font-semibold text-white">
                      {review.name}
                    </h3>
                    <p className="text-sm text-gray-300">{review.position}</p>
                  </div>
                </div>
                <div className="flex-grow flex flex-col justify-start">
                  <p className="text-gray-300 text-base leading-relaxed overflow-hidden line-clamp-6">
                    {review.text}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
