import React from "react";
import { Link } from "react-router-dom";
import blogsData from "../Components/blogs.json";
import Scale from "../Components/Scale";

function BlogsPage() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="bg-gradient-to-r from-[#2a2b3c] via-[#121820] to-[#1f2531] min-h-screen  lg:py-10 py-20 px-4">
      <div className="text-center mb-16 mt-5 text-3xl lg:text-4xl font-bold text-white">
        Latest News & Updates
      </div>

      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {blogsData.map((blog) => (
            <Link
              key={blog.id}
              to={`/blog/${blog.id}`}
              className="group"
              onClick={scrollToTop}
            >
              <div className="bg-gray-800 text-white rounded-md p-6 shadow-lg  transition-all hover:scale-105 duration-300 relative overflow-hidden flex flex-col justify-between h-full ">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-48 object-cover rounded-md mb-4 transition-all"
                />
                <div className="absolute inset-0 bg-black opacity-0 hover:bg-black transition-all duration-300"></div>
                <div className="relative z-10 flex-grow">
                  <h3 className="text-2xl font-semibold mb-4 hover:text-yellow-600">
                    {blog.title}
                  </h3>
                  <p className="text-gray-300 mb-3">Author: {blog.author}</p>
                  <p className="text-gray-400 mb-4">{blog.date}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Scale />
    </div>
  );
}

export default BlogsPage;
