export const questions = [
  {
    question:
      "Are all the latest models available on EfficientAI cloud platform?",
    answer:
      "Any new models available on the cloud providers will be automatically available on EfficientAI cloud platform.",
  },
  {
    question:
      "How are my models and data secure in EfficientAI cloud platform?",
    answer:
      "We securely host & deploy your models inside a Virtual Network, so that only your organisation members having the necessary Role-based access control will be able to access them.",
  },
  {
    question:
      "I have my own cloud provider account / want to self-host EfficientAI platform to deploy my models?",
    answer:
      "We are also working on providing Bring Your Own Cloud (BYOC) and Enterprise edition of EfficientAI platform. Using the BYOC, you can add your cloud provider accounts to EfficientAI cloud platform and manage your models & endpoints remotely. Using the Enterprise edition, you can self-host the EfficientAI platform on-premises / in your own cloud provider account.",
  },
  {
    question: "How does EfficientAI cloud cater to being Enterprise ready?",
    answer:
      "We will be undergoing SOC 2 compliance and ISO 27701 to showcase how we are handling data in our platform.",
  },
];
