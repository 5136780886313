import React from "react";
import { SiMicrosoftazure, SiGooglecloud } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import Xarrow from "react-xarrows";
import { motion } from "framer-motion";
import FlowChartLogo from "../Assets/flowchart-logo.png";

const AnimatedFlowChart = ({ width, heading }) => {
  const textHover = {
    color: "#fca311",
    scale: 1.1,
    transition: {
      type: "spring",
      stiffness: 300,
    },
  };

  const platformAnimation = {
    initial: { opacity: 1 },
    animate: {
      opacity: [1, 1, 1],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div
      className="mx-10 flex flex-col rounded-2xl h-fit items-center justify-center bg-gray-800 text-white  py-10 px-4 space-y-2 relative min-w-fit"
      style={{ width: width }}
    >
      <motion.div
        id="workloads"
        className="text-sm md:text-3xl font-bold border-2  text-white bg-gray-800 p-4 rounded-lg shadow-lg"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Your AI Workloads
      </motion.div>

      <div className="flex items-center   justify-center relative">
        <svg
          width="50"
          height="50"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-2xl text-white"
        >
          <path
            d="M12 4V20M4 12H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </div>


      <div className="flex items-center justify-between w-full gap-6 lg:gap-12 relative border-2 p-2">
        <div className="flex flex-col items-start text-[11px] md:text-lg text-white">
          <motion.div
            id="one-click"
            className="font-semibold mb-4"
            whileHover={textHover}
          >
            One-click deployments
          </motion.div>
          <motion.div
            id="secure"
            className="font-semibold mb-4"
            whileHover={textHover}
          >
            Secure and Enterprise Ready
          </motion.div>
        </div>

        <motion.div
          id="platform"
          className="relative flex flex-col items-center md:mr-6 lg:mr-6  bg-gray-900 p-4 rounded-lg border-2 "
          initial={{ opacity: 1 }}
          animate={platformAnimation.animate}
          transition={{ duration: 0.3 }}
          whileHover={{
            boxShadow: "0 0 30px rgba(234, 179, 8, 0.8)",
          }}
        >
          <img
            src={FlowChartLogo}
            alt="EfficientAI Logo"
            className="w-40 h-auto mb-4"
          />
          <div>
            <div
              id="mlaas"
              className="flex flex-col w-full items-center justify-center bg-gray-800 p-2 rounded-lg border-2 lg:mt-4"
            >
              <h3 className="text-sm md:text-lg text-white font-semibold">
                MLaaS Control Plane
              </h3>
            </div>
          </div>
        </motion.div>

        <div className="flex flex-col items-end space-y-4 text-[11px] md:text-lg text-white">
          <motion.div
            id="tenant"
            className="font-semibold mb-2"
            whileHover={textHover}
          >
            Tenant Isolation
          </motion.div>
          <motion.div
            id="monitoring"
            className="font-semibold mb-2"
            whileHover={textHover}
          >
            Centralized Monitoring & <br></br>Observability
          </motion.div>
          <motion.div
            id="billing"
            className="font-semibold mb-2"
            whileHover={textHover}
          >
            Centralized Billing
          </motion.div>
        </div>
      </div>

      
      
      <div className="flex items-center justify-center relative">
  <svg
    width="50"
    height="50"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="text-2xl text-white"
  >
    <path
      d="M4 8H20M4 16H20"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
</div>

      <div
        id="cloud"
        className="flex text-center rounded-lg flex-col justify-center items-center w-fit md:w-[500px] lg:w-[500px] p-2 max-w-3xl gap-2 mt-20 border-2 "
      >
        <div className="flex justify-center w-fit p-2 max-w-3xl gap-2 md:gap-16 lg:gap-20">
          <div
            id="aws"
            className="flex items-center justify-center bg-gray-800 p-2 rounded-lg border border-gray-700"
          >
            <FaAws className="text-4xl text-white" />
          </div>
          <div
            id="azure"
            className="flex items-center justify-center bg-gray-800 p-2 rounded-lg border border-gray-700"
          >
            <SiMicrosoftazure className="text-4xl text-white" />
          </div>
          <div
            id="google"
            className="flex items-center justify-center bg-gray-800 p-2 rounded-lg border border-gray-700"
          >
            <SiGooglecloud className="text-4xl text-white" />
          </div>
        </div>

        <p>Multicloud AI Deployments</p>
      </div>

      <Xarrow
        start="platform"
        end="one-click"
        color="white"
        strokeWidth={2}
        className="electricity"
      />
      <Xarrow
        start="platform"
        end="secure"
        color="white"
        strokeWidth={2}
        className="electricity"
      />
      <Xarrow
        start="platform"
        end="tenant"
        color="white"
        strokeWidth={2}
        className="electricity"
      />
      <Xarrow
        start="platform"
        end="monitoring"
        color="white"
        strokeWidth={2}
        className="electricity"
      />
      <Xarrow
        start="platform"
        end="billing"
        color="white"
        strokeWidth={2}
        className="electricity"
      />
    
    </div>
  );
};

export default AnimatedFlowChart;
