import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../Styles/hero.css";
import SupportedBy from "./SupportedBy";

const Hero = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="w-full hero flex flex-col items-center text-center px-6 justify-center h-fit md:h-fit bg-gradient-to-r from-[#2a2b3c] via-[#121820] to-[#1f2531] pt-20 md:pt-20 lg:pt-32 pb-10 lg:pb-20 relative">
        <div className="container mx-auto flex flex-col gap-3 items-center relative">
          <motion.h1
            className="text-3xl md:text-4xl lg:text-6xl font-semibold text-white"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Start your <span className="text-yellow-600">Multicloud AI</span>{" "}
            journey in <span className="text-yellow-600">minutes</span>
          </motion.h1>

          <motion.div
            className="text-md lg:flex items-center justify-center gap-4 md:text-lg lg:text-xl text-neutral-200 font-medium mt-5"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <p>Empower your AI journey,</p>
            <div className="inner-words">
              <motion.span
                className="text-yellow-600 font-bold"
                animate={{ opacity: [0, 1], y: [10, 0] }}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                  loop: Infinity,
                  repeatDelay: 1,
                }}
              >
                Train <br />
                Fine Tune <br />
                Deploy <br />
                Scale <br />
              </motion.span>
            </div>
            <p>seamlessly on our all-in-one platform.</p>
          </motion.div>

          <div className="flex items-center gap-4 mt-5 w-full justify-center">
            <motion.button
              onClick={() => setModalOpen(true)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="whitespace-nowrap h-12 text-white border-2 text-center bg-gray-800 hover:bg-gray-700 px-6 py-2 rounded-md text-sm font-semibold w-full sm:w-auto lg:text-lg"
            >
              Watch Demo
            </motion.button>

            <motion.a
              href="https://tally.so/r/nWeMbj"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <button className="whitespace-nowrap inline-flex h-12 animate-shimmer items-center justify-center rounded-md border-2 bg-[linear-gradient(110deg,#000103,40%,#1e2631,55%,#000103)] bg-[length:200%_100%] px-6 font-medium text-white transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-sm lg:text-lg">
                Request Access
              </button>
            </motion.a>
          </div>
        </div>
      </div>

      {/* Video Modal */}
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setModalOpen(false)}
          >
            <motion.div
              className="bg-gray-900 rounded-2xl p-1 md:p-8 lg:p-8 shadow-lg w-11/12 md:w-3/4 lg:w-3/2 relative"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() => setModalOpen(false)}
                className="absolute -top-6 -right-0 md:top-3 md:right-3 lg:top-3 lg:right-3 text-white text-xl hover:text-gray-400"
              >
                ✕
              </button>
              <div className="relative w-full pt-[56.25%]">
                <iframe
                  className="absolute top-0 left-0 w-full h-full rounded-xl"
                  src="https://www.youtube.com/embed/9jPe8jVMfNM?si=MBzAKuo7ZhU4Pr4K"
                  title="YouTube Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <SupportedBy />
    </>
  );
};

export default Hero;
