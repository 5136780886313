import React from "react";
import Hero from "../Components/Hero";
import LogoSlider from "../Components/LogoSlider";

import Capabilities from "../Components/Capabilities";
import Scale from "../Components/Scale";
import Faqs from "../Components/faqs";
import Carousel from "../Components/reviewSlider";
import { reviews } from "../Components/reviews";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePage = () => {
  return (
    <>
      {<Hero />}
      {<LogoSlider />}

      {<Capabilities />}
      {<Carousel reviews={reviews} />}
      {<Faqs />}
      {<Scale />}
    </>
  );
};

export default HomePage;
