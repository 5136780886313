import React from "react";
import { motion } from "framer-motion";

const Scale = () => {
  return (
    <div className="px-4">
      <motion.div
        className="container mx-auto items-center text-center rounded-xl m-16 bg-gray-800 bg-opacity-70 shadow-xl shadow-gray-800 p-10 backdrop-blur-md border border-gray-700"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        <motion.h2
          className="text-3xl lg:text-5xl font-bold text-white"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.8 }}
        >
          Start for free. Scale without limits
        </motion.h2>

        <div className="flex justify-center mt-9">
          <motion.a
            href="https://mlb1u6ibtyr.typeform.com/to/EbeWdMXV"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <button className="whitespace-nowrap inline-flex h-12 animate-shimmer items-center justify-center rounded-md border border-white bg-slate-900 bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] px-6 font-medium text-white transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-sm lg:text-lg">
              Join The Waitlist
            </button>
          </motion.a>
        </div>
      </motion.div>
    </div>
  );
};

export default Scale;
