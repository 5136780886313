import React, { useState } from "react";
import SingleQuestion from "./SingleQuestion";
import { questions } from "./questions";

const Faqs = () => {
  const [cards] = useState(questions);

  return (
    <section className="max-w-full mx-auto py-20 px-6 bg-gradient-to-r from-[#2a2b3c] via-[#121820] to-[#1f2531] text-white">
      <h1 className="text-center text-3xl lg:text-4xl font-semibold text-white mb-20">
        Frequently Asked Questions
      </h1>

      <section className="grid grid-cols-1 gap-2">
        {cards.map((card, index) => (
          <SingleQuestion {...card} key={index} />
        ))}
      </section>
    </section>
  );
};

export default Faqs;
