import React from "react";
import gcpStartups from "../Assets/gcp_startups.png";
import awsStartups from "../Assets/aws_startups.png";
import mongoStartups from "../Assets/mongostartups.png";

const BackedBy = () => {
  return (
    <div className="bg-gradient-to-r from-[#2a2b3c] via-[#121820] to-[#1f2531] py-8 px-4 text-gray-300">
      <div className="container mx-auto text-center">
        <p className="gradient-text text-xl md:text-2xl lg:text-4xl font-semibold mb-10">
          Supported by Tech Leaders
        </p>
        <div className="flex justify-center items-center flex-wrap gap-[5%] md:gap-20 lg:gap-32 text-white">
          <img
            src={gcpStartups}
            alt="GCP Startups"
            className="lg:w-48 mb-2 w-28 md:w-40 h-auto lg:h-auto"
          />
          <img
            src={awsStartups}
            alt="AWS Startups"
            className="lg:w-48 mb-2 w-28 md:w-40 h-auto lg:h-auto"
          />
          <img
            src={mongoStartups}
            alt="Mongo Startups"
            className="lg:w-48 mb-2 w-28 md:w-40 h-auto lg:h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default BackedBy;
