import React from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import blogsData from "../Components/blogs.json";
import { Link } from "react-router-dom";

function BlogDetailPage() {
  const { id } = useParams();
  const blog = blogsData.find((blog) => blog.id === id);

  if (!blog) {
    return (
      <div className="bg-gray-900 min-h-screen flex justify-center items-center py-8">
        <div className="text-white">Blog not found.</div>
      </div>
    );
  }

  const highlightBeforeColon = (text) => {
    return text.split("\n").map((line, index) => {
      const regex = /^(.*?):(.*)$/;
      const match = line.trim().match(regex);

      if (match) {
        return (
          <p key={index}>
            <span className="text-yellow-600">{match[1]}</span>
            {match[2]}
          </p>
        );
      }

      return <p key={index}>{line}</p>;
    });
  };

  return (
    <div className="bg-gradient-to-r from-[#2a2b3c] via-[#121820] to-[#1f2531] min-h-screen py-8 px-4">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="max-w-4xl mx-auto bg-gray-800 text-white rounded-lg p-8 shadow-lg"
      >
        <img
          src={blog.image}
          alt={blog.title}
          className="w-full h-auto object-contain rounded-md mb-6 shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-2xl hover:shadow-gray-900 cursor-pointer"
        />

        <h2 className="text-4xl font-bold mb-4 text-yellow-600">
          {blog.title}
        </h2>

        <div className="flex items-center text-gray-400 mb-6 space-x-4">

          <span  className="font-medium  flex items-center"><img src={blog.authorImage} alt={blog.author} className="w-8 h-8 rounded-full mr-2" /> {blog.author}</span>
          <span>&bull;</span>
          <span>{blog.date}</span>
        </div>

        <div className="space-y-6 text-gray-300">
          {blog.paragraph.map((para, index) => (
            <div key={index}>{highlightBeforeColon(para)}</div>
          ))}
        </div>

        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          <Link
            to="/blogs"
            className="block text-center text-yellow-600 mt-8 hover:underline"
          >
            Back to Blogs
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default BlogDetailPage;
