import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../Assets/logo-header.png";

import { motion , AnimatePresence } from "framer-motion";
import { HiMenu, HiX } from "react-icons/hi";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <AnimatePresence>
    <header className="pb-2  w-full z-50 bg-gradient-to-r from-[#2a2b3c] via-[#121820] to-[#1f2531] ">
      {/* ribbon at top  */}
      <div className="bg-yellow-700  px-10 mb-2 p-2 text-white text-center">
        <div className="flex justify-center items-center">
          <p className="text-white text-lg md:text-base">
            <span className=" font-bold underline "> DeepSeek R1</span> is now
            available on EfficientAI, {" "}
            <a className=" underline" href="https://tally.so/r/nWeMbj">
              Try it now!
            </a>
          </p>
        </div>
      </div>

      {/* main header */}

      <div className="container flex mx-auto w-[90%] lg:w-[80%] justify-between items-center shadow-lg border-2 border-gray-500 rounded-full px-6 py-3 bg-gray-800">
        <NavLink
          to="/"
          className="flex items-center px-4 py-1 justify-center rounded-full"
        >
          <img src={logo} alt="EfficientAI Logo" className="w-9 h-auto" />
          <span className="font-semibold text-white text-lg md:text-xl lg:text-2xl ml-1">
            Efficient<span className="text-yellow-600 font-normal">AI</span>
          </span>
        </NavLink>

        <div className="hidden md:flex items-center space-x-12 relative  text-lg">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600 font-semibold transition-colors"
                : "text-white hover:text-yellow-600 font-semibold transition-colors"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600 font-semibold transition-colors"
                : "text-white hover:text-yellow-600 font-semibold transition-colors"
            }
          >
            About
          </NavLink>
          <NavLink
            to="/blogs"
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600 font-semibold transition-colors"
                : "text-white hover:text-yellow-600 font-semibold transition-colors"
            }
          >
            Blogs
          </NavLink>
        </div>

        <div className="hidden md:block">
          <NavLink to="https://calendly.com/tejasnarayan">
            <button className="bg-gray-800 text-white border-2 px-4 py-2 transition-all duration-150 rounded-md font-bold hover:bg-gray-700 hover:scale-105">
              Book a Demo
            </button>
          </NavLink>
        </div>

        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            {menuOpen ? <HiX size={30} /> : <HiMenu size={30} />}
          </button>
        </div>
      </div>

      {menuOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "100vh" }}
          exit={{ opacity: 0, height: 0 }}
          
          transition={{ duration: 0.5 }}
          className="fixed top-0 left-0 w-full h-full bg-gray-800 text-white flex flex-col items-center justify-start z-20 p-10"
        >
          <button
            onClick={closeMenu}
            className="absolute top-5 right-5 text-white"
          >
            <HiX size={30} />
          </button>
          <NavLink
            onClick={closeMenu}
            to="/"
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600 font-semibold transition-colors  px-4 py-5  text-lg"
                : "text-white hover:text-yellow-600 font-semibold transition-colors  px-4 py-5 text-lg "
            }
          >
            Home
          </NavLink>
          <NavLink
            onClick={closeMenu}
            to="/about"
          
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600 font-semibold transition-colors  px-4 py-5  text-lg"
                : "text-white hover:text-yellow-600 font-semibold transition-colors  px-4 py-5 text-lg "
            }
           
          >
            About Us
          </NavLink>
          <NavLink
            onClick={closeMenu}
            to="/blogs"
            className={({ isActive }) =>
              isActive
                ? "text-yellow-600 font-semibold transition-colors  px-4 py-5  text-lg"
                : "text-white hover:text-yellow-600 font-semibold transition-colors  px-4 py-5 text-lg "
            }
          >
            Blogs
          </NavLink>

          <NavLink to="https://calendly.com/tejasnarayan">
            <button className="bg-gray-800 text-white border-2 px-4 py-4 transition-all duration-150 rounded-md font-bold hover:bg-gray-700 hover:scale-105 mt-2">
              Book a Demo
            </button>
          </NavLink>
        </motion.div>
      )}
    </header>
    </AnimatePresence>
  );
};

export default Header;
