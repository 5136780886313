import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../Assets/logo-header.png";
import linkdin from "../Assets/Component 8.png";
import twitter from "../Assets/Component 9.png";
import gmail from "../Assets/Vector (2).png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="w-full bg-gray-900 text-gray-300 py-16 px-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="flex flex-col items-start">
          <NavLink
            to="/"
            onClick={scrollToTop}
            className="flex items-center gap-3 mb-4"
          >
            <img src={logo} alt="Efficient AI Logo" className="w-12 h-auto" />
            <span className="font-bold text-gray-100 text-2xl">
              Efficient<span className="text-yellow-600">AI</span>
            </span>
          </NavLink>
        </div>

        <div className="flex flex-col">
          <h3 className="text-lg font-semibold mb-3 text-gray-100">
            Quick Links
          </h3>
          <ul className="space-y-2">
            <li>
              <NavLink
                to="/"
                onClick={scrollToTop}
                className={({ isActive }) =>
                  isActive ? "text-yellow-600" : "hover:text-yellow-600"
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                onClick={scrollToTop}
                className={({ isActive }) =>
                  isActive ? "text-yellow-600" : "hover:text-yellow-600"
                }
              >
                About Us
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/blogs"
                onClick={scrollToTop}
                className={({ isActive }) =>
                  isActive ? "text-yellow-600" : "hover:text-yellow-600"
                }
              >
                Blog
              </NavLink>
            </li>
            {/* future links */}
            {/* <li>
              <NavLink 
                to="/contact" 
                onClick={scrollToTop}
                className={({ isActive }) => 
                  isActive ? "text-yellow-600" : "hover:text-yellow-600"
                }
              >
                Contact Us
              </NavLink>
            </li> */}
          </ul>
        </div>

        {/* Social Media and Contact */}
        <div className="flex flex-col">
          <h3 className="text-lg font-semibold mb-3 text-gray-100">
            Stay Connected
          </h3>
          <div className="flex gap-6 mb-4">
            <a
              href="https://www.linkedin.com/company/efficientaicloud"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkdin} alt="LinkedIn" className="w-6 h-6" />
            </a>
            <a
              href="https://x.com/AiEfficient"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Twitter" className="w-6 h-6" />
            </a>
            <a href="mailto:contact@efficientai.cloud">
              <img src={gmail} alt="Email" className="w-6 h-6" />
            </a>
          </div>
          <p className="text-sm">contact@efficientai.cloud</p>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-gray-700 mt-8 pt-4">
        <p className="text-center text-sm">
          © 2024 EfficientAI.cloud. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
