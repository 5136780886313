import React from "react";
import Lottie from "lottie-react";
import { motion } from "framer-motion";
import autoscalingAnimation from "../Assets/animations/autoscaling.json";
import multicloudAnimation from "../Assets/animations/multicloud.json";
import unified from "../Assets/animations/unified.json";
import finetuning from "../Assets/animations/finetune.json";

const capabilities = [
  {
    id: 1,
    title: "Auto-scaling Enabled",
    paragraph:
      "Automatically scale infrastructure for model deployments in real-time to optimize efficiency and cost based on demand.",
    animation: autoscalingAnimation,
  },
  {
    id: 2,
    title: "Unified Model Fine-tuning",
    paragraph:
      "Refine model parameters across cloud providers for optimal accuracy and efficiency in line with evolving data and business demands.",
    animation: finetuning,
  },
  {
    id: 3,
    title: "One Click Multi-Cloud Deployments",
    paragraph:
      "Deploy models across multiple cloud providers in minutes to leverage the best of each platform and avoid vendor lock-in.",
    animation: multicloudAnimation,
  },
  {
    id: 4,
    title: "Unified Monitoring & Billing",
    paragraph:
      "Centralized monitoring, billing, and observability of your multi-cloud model deployments for comprehensive control and streamlined operations.",
    animation: unified,
  },
];

const Capabilities = () => {
  const Card = ({ animation, title, paragraph }) => {
    return (
      <motion.div
        whileHover={{ scale: 1.03 }}
        transition={{ duration: 0.3 }}
        className="flex border-2  bg-black bg-opacity-50 backdrop-blur-lg p-4 rounded-lg shadow-md overflow-hidden transition-transform max-w-[1000px] mx-auto"
      >
        <div className="flex-shrink-0 lg:mr-6">
          <Lottie
            animationData={animation}
            loop={true}
            autoplay={true}
            style={{ width: 80, height: 80 }}
          />
        </div>

        <div className="flex flex-col justify-center border border-gray-700 p-4 rounded-lg bg-gray-800 bg-opacity-30">
          <h3 className="text-xl font-semibold text-gray-100 mb-2">{title}</h3>
          <p className="text-sm text-gray-300">{paragraph}</p>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="bg-gradient-to-r from-[#2a2b3c] via-[#121820] to-[#1f2531]   border-white py-20 px-6">
      <div className="container mx-auto text-center mb-20">
        <h2 className="text-3xl lg:text-4xl  font-bold text-gray-100 mb-16">
          Capabilities
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {capabilities.map((item) => (
          <Card
            key={item.id}
            animation={item.animation}
            title={item.title}
            paragraph={item.paragraph}
          />
        ))}
      </div>
    </div>
  );
};

export default Capabilities;
