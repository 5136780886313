import React from "react";
import { motion } from "framer-motion";
import "../Styles/hero.css";
import SupportedBy from "./SupportedBy";

const Hero = () => {
  return (
    <>
      <div className="w-full hero flex items-center px-6 justify-center h-fit lg:h-screen md:h-fit bg-gradient-to-r from-[#2a2b3c] via-[#121820] to-[#1f2531] pt-20 md:pt-20 lg:pt-10 pb-16 lg:pb-10 relative">
        <div className="container mx-auto flex flex-col md:flex-row items-center relative">
          <div className="flex flex-col items-center md:items-start gap-10 text-center md:text-left max-w-2xl md:pr-10">
            <motion.h1
              className="text-3xl md:text-5xl lg:text-7xl font-semibold text-white"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Start your <span className="text-yellow-600">Multicloud AI</span>{" "}
              journey in <span className="text-yellow-600">minutes</span>
            </motion.h1>

            <motion.div
              className="flex justify-center items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="text-xl md:text-lg text-neutral-400 font-medium">
              <p>Empower your AI journey</p>
                <div className="inner-words">
                  <motion.span
                    className="text-yellow-600 font-bold"
                    animate={{ opacity: [0, 1], y: [10, 0] }}
                    transition={{
                      duration: 0.5,
                      ease: "easeInOut",
                      loop: Infinity,
                      repeatDelay: 1,
                    }}
                  >
                    Train <br />
                    Fine Tune <br />
                    Deploy <br />
                    Scale <br />
                  </motion.span>
                </div>
               <p>seamlessly on our all-in-one platform.</p> 
              </div>
            </motion.div>
          </div>

          <div className="flex w-full flex-col items-center mt-10 md:mt-0 mx-10 md:flex-grow">
            <motion.iframe
              title="EfficientAI"
              className="w-[100%] lg:w-[600px] md:w-[450px] aspect-video rounded-lg shadow-2xl"
              src="https://www.youtube.com/embed/9jPe8jVMfNM?si=MBzAKuo7ZhU4Pr4K&controls=1&modestbranding=1&rel=0&showinfo=0"
              style={{ border: "0" }}
              allow="fullscreen; autoplay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            ></motion.iframe>

            <div className="flex gap-4 mt-5 w-full justify-center">
              <motion.a
                href="https://calendly.com/tejasnarayan"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <button className="whitespace-nowrap h-12 text-white border-2 text-center bg-gray-800 hover:bg-gray-700 px-6 py-2 rounded-md text-sm font-semibold w-full sm:w-auto lg:text-lg">
                  Book a Demo
                </button>
              </motion.a>

              <motion.a
                href="https://tally.so/r/nWeMbj"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <button className="whitespace-nowrap inline-flex h-12 animate-shimmer items-center justify-center rounded-md  border-2 bg-[linear-gradient(110deg,#000103,40%,#1e2631,55%,#000103)] bg-[length:200%_100%] px-6 font-medium text-white transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-sm lg:text-lg">
                Request Access
                </button>
              </motion.a>
            </div>
          </div>
        </div>
      </div>
      <SupportedBy />
    </>
  );
};

export default Hero;
