import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/HomePage";
import BlogsPage from "./Pages/BlogsPage";
// import PricingPage from "./Pages/PricingPage";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
// import ContactForm from "./Components/ContactForm";
import AboutPage from "./Pages/AboutPage";
import BlogDetailPage from "./Components/BlogPageDetail";

const App = () => {
  return (
    <div className="overflow-hidden bg-gradient-to-r from-[#2a2b3c] via-[#121820] to-[#1f2531]">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blog/:id" element={<BlogDetailPage />} />
          <Route path="/about" element={<AboutPage />} />
          {/* <Route path="/pricing" element={<PricingPage />} /> */}
          {/* <Route path="/contact" element={<ContactForm />} /> */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
